import React, { useState } from "react";
import loadable from "@loadable/component";
import "../../styles/blocks/tabs.scss";
const Parser = loadable(() => import("../parser.js"));

const LockedGroupTabs = (props) => {
  const [tabShown, showTab] = useState(0);
  let className = "tabs ";
  className += (props.attributes.className || "");
  if (props.attributes.backgroundColor) {
    className += " has-"+props.attributes.backgroundColor+"-background-color";
  }
  return (<div id={ props.anchor } className={ className } style={ props.style }>
    {
      props.innerBlocks.map((tab,tabId) => {
        return (<>
          {
            tab.innerBlocks.map((element,i) => {
              if (i === 0) {
                const level = element.attributes.level || 2;
                const TagName = "h"+level;
                let className = "tab-title";
                className += (element.attributes.className) ? " "+element.attributes.className:"";
                className += (tab.attributes.backgroundColor) ? " has-"+tab.attributes.backgroundColor+"-background-color":"";
                if (element.attributes.textAlign) {
                  className += " has-text-align-"+element.attributes.textAlign;
                }
                return (<TagName
                  id={ element.anchor }
                  data-shown={tabShown === tabId}
                  style={{"--id": tabId}}
                  key={i}
                  className={className}>
                  <button onClick={ () => { showTab(tabId); } }>
                    <Parser content={ (element.attributes.content || "") } />
                  </button>
                </TagName>);
              }
              return (<div
                data-shown={tabShown === tabId}
                className={ "tab-content"+((tab.attributes.backgroundColor) ? " has-"+tab.attributes.backgroundColor+"-background-color":"") }
                key={i}>
                <Parser content={ element.innerHTML } />
              </div>);
            })
          }
        </>);
      })
    }
  </div>);
};


export default LockedGroupTabs;
